import { useQuery } from '@tanstack/react-query';
import { adminApi } from '../services/api';
import { Activity } from '../types/api';

export const useWeekActivities = (weekId: number | null) => {
  return useQuery<Activity[]>({
    queryKey: ['weekActivities', weekId],
    queryFn: () => {
      if (!weekId) return Promise.resolve([]);
      return adminApi.getWeekActivities(weekId);
    },
    enabled: !!weekId,
  });
}; 