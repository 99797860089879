import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { WeeklySchedule } from '../../types/api';

interface WeeklyProgressChartProps {
  data: WeeklySchedule[];
  onBarClick?: (weekId: number) => void;
}

const WeeklyProgressChart = ({ data, onBarClick }: WeeklyProgressChartProps) => {
  const handleClick = (data: any) => {
    if (data && data.payload && onBarClick) {
      onBarClick(data.payload.id);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="weekNumber" />
        <YAxis />
        <Tooltip />
        <Bar 
          dataKey="totalDistance" 
          name="Distance" 
          fill="#8884d8"
          cursor="pointer"
          onClick={handleClick}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default WeeklyProgressChart; 