import axios from 'axios';
import { PerformedActivity, User, UserProfile } from '../types/api';
import { config } from '../config/env';
import { WeeklySchedule } from '../types/api';
import { Goal } from '../types/api';
import { Activity } from '../types/api';
import { LoginResponse, LoginCredentials } from '../types/api';

const isDevelopment = process.env.NODE_ENV === 'development';

const api = axios.create({
  baseURL: config.apiUrl,
});

// Add request interceptor for logging in development
if (isDevelopment) {
  api.interceptors.request.use((config) => {
    console.log('🚀 API Request:', {
      url: config.url,
      method: config.method,
      data: config.data,
    });
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      console.log('✅ API Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data,
      });
      return response;
    },
    (error) => {
      console.error('❌ API Error:', {
        url: error.config?.url,
        status: error.response?.status,
        data: error.response?.data,
      });
      return Promise.reject(error);
    }
  );
}

// Add request interceptor to handle errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle specific error cases
    if (error.response?.status === 401) {
      // Handle unauthorized
      // You might want to redirect to login or refresh token
    }
    return Promise.reject(error);
  }
);

// Add this before the existing interceptors
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  // Add OPTIONS method handling for preflight
  if (config.method?.toLowerCase() === 'options') {
    config.headers['Access-Control-Request-Method'] = 'POST';
    config.headers['Access-Control-Request-Headers'] = 'content-type,authorization';
  }
  
  return config;
});

export const adminApi = {
  getUsers: async (): Promise<User[]> => {
    const response = await api.get('/api/admin/users');
    return response.data;
  },
  getWeeklySchedule: async (userId: string): Promise<WeeklySchedule[]> => {
    const response = await api.get(`/api/admin/users/${userId}/weekly`);
    return response.data;
  },
  getGoals: async (userId: string): Promise<Goal[]> => {
    const response = await api.get(`/api/admin/users/${userId}/goals`);
    return response.data;
  },
  getWeekActivities: async (weekId: number): Promise<Activity[]> => {
    const response = await api.get(`/api/admin/week/${weekId}/activities`);
    return response.data;
  },
  regenerateWeekActivities: async (weekId: number): Promise<void> => {
    await api.post(`/api/admin/week/${weekId}/activities/regenerate`);
  },
  login: async (credentials: LoginCredentials): Promise<LoginResponse> => {
    const response = await api.post('/api/admin/login', credentials);
    return response.data;
  },
  getPerformedActivities: async (weekId: number): Promise<PerformedActivity[]> => {
    const response = await api.get(`/api/admin/week/${weekId}/performed-activities`);
    return response.data;
  },
  getUserProfile: async (userId: string): Promise<UserProfile> => {
    const response = await api.get(`/api/admin/users/${userId}/profile`);
    return response.data;
  },
  updateUserProfile: async (userId: string, profile: string): Promise<void> => {
    await api.post(`/api/admin/users/${userId}/profile/post`, { profile });
  },
}; 