import { useParams } from 'react-router-dom';
import { Box, Tab, Tabs, Typography, Divider } from '@mui/material';
import { useState } from 'react';
import WeeklySchedule from './WeeklySchedule';
import Goals from './Goals';
import UserProfile from '../../components/UserProfile';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const AthleteDetail = () => {
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom>
        Athlete Details - ID: {id}
      </Typography>

      <Box sx={{ mb: 4 }}>
        <UserProfile userId={id!} />
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Goals" />
          <Tab label="Weekly Schedule" />
          <Tab label="Planned Workouts" />
          <Tab label="Executed Workouts" />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0}>
        <Goals userId={id!} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <WeeklySchedule userId={id!} />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography>Planned Workouts</Typography>
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Typography>Executed Workouts</Typography>
      </TabPanel>
    </Box>
  );
};

export default AthleteDetail; 