import { useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useWeeklySchedule } from '../../hooks/useWeeklySchedule';
import { useWeekActivities } from '../../hooks/useWeekActivities';
import WeeklyProgressChart from './WeeklyProgressChart';
import ActivityModal from '../../components/ActivityModal';

interface WeeklyScheduleProps {
  userId: string;
}

const WeeklySchedule = ({ userId }: WeeklyScheduleProps) => {
  const { data: schedule, isLoading, error } = useWeeklySchedule(userId);
  const [selectedWeekId, setSelectedWeekId] = useState<number | null>(null);
  
  const { 
    data: activities = [],
    isLoading: activitiesLoading 
  } = useWeekActivities(selectedWeekId);

  const handleWeekSelect = (weekId: number) => {
    setSelectedWeekId(weekId);
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading schedule: {error instanceof Error ? error.message : 'Unknown error'}
      </Alert>
    );
  }

  const selectedWeek = schedule?.find(week => week.id === selectedWeekId);

  return (
    <Box>
      <WeeklyProgressChart 
        data={schedule || []} 
        onBarClick={(weekId) => handleWeekSelect(weekId)}
      />
      
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Week</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Distance</TableCell>
              <TableCell>Phase</TableCell>
              <TableCell>Focus</TableCell>
              <TableCell>Notes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedule?.map((week) => (
              <TableRow 
                key={week.id}
                hover
                onClick={() => handleWeekSelect(week.id)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{week.weekNumber}</TableCell>
                <TableCell>{week.year}</TableCell>
                <TableCell>{week.totalDistance}km</TableCell>
                <TableCell>{week.phase}</TableCell>
                <TableCell>{week.focus}</TableCell>
                <TableCell>{week.notes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ActivityModal
        open={!!selectedWeekId}
        onClose={() => setSelectedWeekId(null)}
        activities={activities}
        weekNumber={selectedWeek?.weekNumber}
        weekId={selectedWeekId || undefined}
        isLoading={activitiesLoading}
      />
    </Box>
  );
};

export default WeeklySchedule; 