import { Box, Typography } from '@mui/material';
import AthleteCountWidget from '../components/AthleteCountWidget';

const DashboardHome = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard Overview
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
        <AthleteCountWidget />
        {/* Add more widgets here as needed */}
      </Box>
    </Box>
  );
};

export default DashboardHome; 