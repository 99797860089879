export const config = {
  apiUrl: process.env.REACT_APP_API_URL,
};

// Validate required environment variables
const requiredEnvVars = ['REACT_APP_API_URL'] as const;

requiredEnvVars.forEach((envVar) => {
  if (!process.env[envVar]) {
    throw new Error(`Missing required environment variable: ${envVar}`);
  }
}); 