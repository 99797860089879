import { Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { useUsers } from '../hooks/useUsers';

const AthleteCountWidget = () => {
  const { data: users, isLoading, error } = useUsers();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error loading athletes</Typography>;
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          Total Athletes
        </Typography>
        <Typography variant="h2" component="div" sx={{ mt: 2 }}>
          {users?.length || 0}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default AthleteCountWidget; 