import { 
  Modal, 
  Box, 
  Typography, 
  Chip,
  IconButton,
  Stack,
  Button,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Activity } from '../types/api';
import { adminApi } from '../services/api';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { usePerformedActivities } from '../hooks/usePerformedActivities';

interface ActivityModalProps {
  open: boolean;
  onClose: () => void;
  activities: Activity[];
  weekNumber?: number;
  weekId?: number;
  isLoading?: boolean;
}

const ActivityModal = ({ open, onClose, activities, weekNumber, weekId, isLoading }: ActivityModalProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const queryClient = useQueryClient();
  const { data: performedActivities = [], isLoading: performedLoading } = usePerformedActivities(weekId || null);

  const handleRegenerateActivities = async () => {
    if (!weekId) return;
    
    setIsRegenerating(true);
    try {
      await adminApi.regenerateWeekActivities(weekId);
      await queryClient.invalidateQueries({ queryKey: ['weekActivities', weekId] });
    } catch (error) {
      console.error('Failed to regenerate activities:', error);
    } finally {
      setIsRegenerating(false);
    }
  };

  // Match performed activities with planned activities
  const matchedActivities = activities.map(planned => {
    const performed = performedActivities.find(p => 
      new Date(p.date).toISOString().split('T')[0] === planned.date
    );
    return { planned, performed };
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 900,
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
        overflow: 'auto',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Typography variant="h6">
            Week {weekNumber} Activities
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} sx={{ mb: 2 }}>
          <Tab label="Combined View" />
          <Tab label="Planned Activities" />
          <Tab label="Performed Activities" />
        </Tabs>

        {isLoading || performedLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {tabValue === 0 && (
              <Box>
                {matchedActivities.map(({ planned, performed }) => (
                  <Box
                    key={planned.id}
                    sx={{
                      mb: 3,
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1">
                        {new Date(planned.date).toLocaleDateString()} - {planned.type}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Chip 
                          label={`Planned: ${planned.totalDistance}km`}
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                        {performed && (
                          <Chip 
                            label={`Actual: ${performed.totalDistance.toFixed(1)}km`}
                            color="secondary"
                            size="small"
                          />
                        )}
                      </Stack>
                    </Box>

                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <Box flex={1}>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          Planned
                        </Typography>
                        {planned.blocks.map((block) => (
                          <Box 
                            key={block.id}
                            sx={{ 
                              display: 'flex',
                              gap: 2,
                              mb: 1,
                              borderLeft: 3,
                              borderColor: 
                                block.type === 'warmup' ? 'info.main' :
                                block.type === 'main' ? 'success.main' : 'error.main',
                              pl: 2
                            }}
                          >
                            <Typography variant="body2" sx={{ width: 80 }}>
                              {block.type}
                            </Typography>
                            <Typography variant="body2" sx={{ width: 100 }}>
                              {block.distance} km
                            </Typography>
                            <Typography variant="body2">
                              {block.pace} min/km
                            </Typography>
                          </Box>
                        ))}
                      </Box>

                      {performed && (
                        <Box flex={1}>
                          <Typography variant="body2" color="text.secondary" gutterBottom>
                            Performed
                          </Typography>
                          {performed.blocks.map((block) => (
                            <Box 
                              key={block.id}
                              sx={{ 
                                display: 'flex',
                                gap: 2,
                                mb: 1,
                                borderLeft: 3,
                                borderColor: 'grey.400',
                                pl: 2,
                                alignItems: 'center'
                              }}
                            >
                              <Typography variant="body2" sx={{ width: 100 }}>
                                {block.distance} km
                              </Typography>
                              <Typography variant="body2" sx={{ width: 100 }}>
                                {block.pace} min/km
                              </Typography>
                              {block.averageHeartRate && (
                                <Chip
                                  size="small"
                                  label={`${Math.round(block.averageHeartRate)} bpm`}
                                  color={
                                    block.averageHeartRate > 170 ? 'error' :
                                    block.averageHeartRate > 150 ? 'warning' :
                                    'default'
                                  }
                                  sx={{ minWidth: 80 }}
                                />
                              )}
                              {block.notes && (
                                <Typography variant="body2" color="text.secondary">
                                  {block.notes}
                                </Typography>
                              )}
                            </Box>
                          ))}
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                              Avg HR: {performed.averageHeartRate?.toFixed(0)} bpm | 
                              Max HR: {performed.maxHeartRate} bpm | 
                              Elevation: {performed.elevationGain}m
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {tabValue === 1 && (
              <Box>
                {activities.map((activity) => (
                  <Box
                    key={activity.id}
                    sx={{
                      mb: 3,
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Typography variant="subtitle1">
                        {new Date(activity.date).toLocaleDateString()} - {activity.type}
                      </Typography>
                      <Chip 
                        label={`${activity.totalDistance}km`}
                        color="primary"
                        size="small"
                      />
                    </Box>

                    <Typography color="text.secondary" sx={{ mb: 2 }}>
                      {activity.notes}
                    </Typography>

                    <Box sx={{ pl: 2 }}>
                      {activity.blocks.map((block) => (
                        <Box 
                          key={block.id}
                          sx={{ 
                            display: 'flex',
                            gap: 2,
                            mb: 1,
                            borderLeft: 3,
                            borderColor: 
                              block.type === 'warmup' ? 'info.main' :
                              block.type === 'main' ? 'success.main' : 'error.main',
                            pl: 2
                          }}
                        >
                          <Typography variant="body2" sx={{ width: 80 }}>
                            {block.type}
                          </Typography>
                          <Typography variant="body2" sx={{ width: 100 }}>
                            {block.distance} km
                          </Typography>
                          <Typography variant="body2">
                            {block.pace} min/km
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}

            {tabValue === 2 && (
              <Box>
                {performedActivities.map((activity) => (
                  <Box
                    key={activity.id}
                    sx={{
                      mb: 3,
                      p: 2,
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                      <Box>
                        <Typography variant="subtitle1">
                          {new Date(activity.date).toLocaleDateString()} - {activity.type}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {new Date(activity.startTime).toLocaleTimeString()} - {new Date(activity.endTime).toLocaleTimeString()}
                        </Typography>
                      </Box>
                      <Stack direction="row" spacing={1}>
                        <Chip 
                          label={`${activity.totalDistance.toFixed(1)}km`}
                          color="primary"
                          size="small"
                        />
                        <Chip 
                          label={`${activity.actualPace} min/km`}
                          color="secondary"
                          size="small"
                        />
                      </Stack>
                    </Box>

                    {activity.notes && (
                      <Typography color="text.secondary" sx={{ mb: 2 }}>
                        {activity.notes}
                      </Typography>
                    )}

                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                      <Typography variant="body2">
                        Moving Time: {activity.movingTime.toFixed(1)} min
                      </Typography>
                      <Typography variant="body2">
                        Elevation: {activity.elevationGain}m
                      </Typography>
                      {activity.averageHeartRate && (
                        <>
                          <Typography variant="body2">
                            Avg HR: {activity.averageHeartRate.toFixed(0)} bpm
                          </Typography>
                          <Typography variant="body2">
                            Max HR: {activity.maxHeartRate} bpm
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box sx={{ pl: 2 }}>
                      {activity.blocks.map((block) => (
                        <Box 
                          key={block.id}
                          sx={{ 
                            display: 'flex',
                            gap: 2,
                            mb: 1,
                            borderLeft: 3,
                            borderColor: 'grey.400',
                            pl: 2,
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="body2" sx={{ width: 100 }}>
                            {block.distance} km
                          </Typography>
                          <Typography variant="body2" sx={{ width: 100 }}>
                            {block.pace} min/km
                          </Typography>
                          {block.averageHeartRate && (
                            <Chip
                              size="small"
                              label={`${Math.round(block.averageHeartRate)} bpm`}
                              color={
                                block.averageHeartRate > 170 ? 'error' :
                                block.averageHeartRate > 150 ? 'warning' :
                                'default'
                              }
                              sx={{ minWidth: 80 }}
                            />
                          )}
                          {block.notes && (
                            <Typography variant="body2" color="text.secondary">
                              {block.notes}
                            </Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </>
        )}

        {weekId && (
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button 
              onClick={handleRegenerateActivities}
              variant="outlined"
              color="primary"
              disabled={isRegenerating}
              startIcon={isRegenerating ? <CircularProgress size={20} /> : null}
            >
              {isRegenerating ? 'Regenerating...' : 'Regenerate Activities'}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ActivityModal;