import { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  CircularProgress, 
  Alert,
  Paper 
} from '@mui/material';
import { useUserProfile } from '../hooks/useUserProfile';

interface UserProfileProps {
  userId: string;
}

const UserProfile = ({ userId }: UserProfileProps) => {
  const { profile, isLoading, error, updateProfile, isUpdating } = useUserProfile(userId);
  const [editedProfile, setEditedProfile] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (profile) {
      setEditedProfile(profile);
    }
  }, [profile]);

  const formatProfile = (profileStr: string) => {
    try {
      const parsed = JSON.parse(profileStr);
      return JSON.stringify(parsed, null, 2);
    } catch {
      return profileStr;
    }
  };

  const handleSave = async () => {
    try {
      await updateProfile(editedProfile);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update profile:', error);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading profile: {error instanceof Error ? error.message : 'Unknown error'}
      </Alert>
    );
  }

  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Runner Profile</Typography>
        {!isEditing && (
          <Button 
            variant="outlined" 
            onClick={() => setIsEditing(true)}
          >
            Edit
          </Button>
        )}
      </Box>

      {isEditing ? (
        <Box>
          <TextField
            multiline
            rows={12}
            fullWidth
            value={editedProfile}
            onChange={(e) => setEditedProfile(e.target.value)}
            sx={{ mb: 2 }}
            InputProps={{
              sx: {
                fontFamily: 'monospace',
                fontSize: '0.9rem',
              },
            }}
          />
          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
            <Button 
              variant="outlined" 
              onClick={() => setIsEditing(false)}
              disabled={isUpdating}
            >
              Cancel
            </Button>
            <Button 
              variant="contained" 
              onClick={handleSave}
              disabled={isUpdating}
            >
              {isUpdating ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box 
          sx={{ 
            maxHeight: '400px',
            overflow: 'auto',
            bgcolor: 'grey.50',
            p: 2,
            borderRadius: 1,
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'grey.100',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: '4px',
            },
          }}
        >
          <pre 
            style={{ 
              margin: 0,
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
              fontFamily: 'monospace',
              fontSize: '0.9rem',
            }}
          >
            {formatProfile(profile || '')}
          </pre>
        </Box>
      )}
    </Paper>
  );
};

export default UserProfile;