import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Chip,
} from '@mui/material';
import { useGoals } from '../../hooks/useGoals';

interface GoalsProps {
  userId: string;
}

const Goals = ({ userId }: GoalsProps) => {
  const { data: goals, isLoading, error } = useGoals(userId);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error">
        Error loading goals: {error instanceof Error ? error.message : 'Unknown error'}
      </Alert>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Distance</TableCell>
            <TableCell>Target Time</TableCell>
            <TableCell>Target Pace</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {goals?.map((goal) => (
            <TableRow key={goal.id}>
              <TableCell>{goal.name}</TableCell>
              <TableCell>
                {new Date(goal.startDate).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <Chip 
                  label={goal.type} 
                  color="primary" 
                  variant="outlined" 
                  size="small" 
                />
              </TableCell>
              <TableCell>{goal.distance}km</TableCell>
              <TableCell>{goal.targetTime}</TableCell>
              <TableCell>{goal.targetPace}/km</TableCell>
              <TableCell>{goal.details}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Goals; 