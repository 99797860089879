import { useQuery } from '@tanstack/react-query';
import { adminApi } from '../services/api';
import { PerformedActivity } from '../types/api';

export const usePerformedActivities = (weekId: number | null) => {
  return useQuery<PerformedActivity[]>({
    queryKey: ['performedActivities', weekId],
    queryFn: () => {
      if (!weekId) return Promise.resolve([]);
      return adminApi.getPerformedActivities(weekId);
    },
    enabled: !!weekId,
  });
};