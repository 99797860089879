import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { adminApi } from '../services/api';
import { UserProfile } from '../types/api';

export const useUserProfile = (userId: string) => {
  const queryClient = useQueryClient();

  const query = useQuery<UserProfile>({
    queryKey: ['userProfile', userId],
    queryFn: () => adminApi.getUserProfile(userId),
  });

  const mutation = useMutation({
    mutationFn: (profile: string) => adminApi.updateUserProfile(userId, profile),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userProfile', userId] });
    },
  });

  return {
    profile: query.data?.profile,
    isLoading: query.isLoading,
    error: query.error,
    updateProfile: mutation.mutate,
    isUpdating: mutation.isPending,
  };
};